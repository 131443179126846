import { Dispatch } from 'redux';

import {
  ActionTypes,
  ModalsAction
} from './types';

export const pushModal = (modal: HTMLDivElement) => (dispatch: Dispatch<ModalsAction>) => {
  dispatch({
    type: ActionTypes.PUSH,
    modal
  });
};

export const popModal = () => (dispatch: Dispatch<ModalsAction>) => {
  dispatch({
    type: ActionTypes.POP
  });
};