import { createSelector } from 'reselect';

import { State } from '../rootReducer';
import { BakeriesState, ActionTypes } from './types';

export const getBakeriesState = ((state: State) => state.bakeries);

export const isCreated = createSelector([getBakeriesState], (s: BakeriesState) =>
  s.state === ActionTypes.CREATED);

export const isDeleted = createSelector([getBakeriesState], (s: BakeriesState) =>
  s.state === ActionTypes.DELETED);

export const isUpdated = createSelector([getBakeriesState], (s: BakeriesState) =>
  s.state === ActionTypes.UPDATED);

export const isPending = createSelector([getBakeriesState], (s: BakeriesState) =>
  s.state === ActionTypes.PENDING);

export const isLoading = createSelector([getBakeriesState], (s: BakeriesState) =>
  s.state === ActionTypes.LOADING);

export const getBakeries = createSelector([getBakeriesState], (s: BakeriesState) => s.data);