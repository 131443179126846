import { Action } from '../types';
import { Project } from '../../models/Project';

export enum ActionTypes {
  INITIAL = 'project/INITIAL',

  COLLABORATORS_UPDATING = 'project/COLLABORATORS_UPDATING',
  COLLABORATORS_UPDATED = 'project/COLLABORATORS_UPDATED',

  CREATING = 'project/CREATING',
  CREATED = 'project/CREATED',

  DELETING = 'project/DELETING',
  DELETED = 'project/DELETED',

  UPDATING = 'project/UPDATING',
  UPDATED = 'project/UPDATED',
}

export interface ProjectState {
  data: Project | null;
  error: boolean;
  state: ActionTypes;
}

interface InitialProjectAction {
  type: ActionTypes.INITIAL;
}

interface CreatingProjectAction {
  type: ActionTypes.CREATING;
}

interface LoadedProjectAction extends Action {
  type: ActionTypes.CREATED;
  payload: Project;
}

interface DeletingProjectAction {
  type: ActionTypes.DELETING;
}

interface DeletedProjectAction extends Action {
  type: ActionTypes.DELETED;
}

interface UpdatingProjectAction {
  type: ActionTypes.UPDATING;
}

interface UpdatedProjectAction extends Action {
  type: ActionTypes.UPDATED;
}

interface CollaboratorsUpdatingProjectAction {
  type: ActionTypes.COLLABORATORS_UPDATING;
}

interface CollaboratorsUpdatedProjectAction extends Action {
  type: ActionTypes.COLLABORATORS_UPDATED;
}

export type ProjectAction = InitialProjectAction
  | CreatingProjectAction
  | LoadedProjectAction
  | DeletingProjectAction
  | DeletedProjectAction
  | UpdatingProjectAction
  | UpdatedProjectAction
  | CollaboratorsUpdatingProjectAction
  | CollaboratorsUpdatedProjectAction;