export enum ActionTypes {
  PUSH = 'modals/PUSH',
  POP = 'modals/POP'
}

export interface ModalsState {
  queue: any[];
}

interface PushModalsAction {
  type: ActionTypes.PUSH;
  modal: any;
}

interface PopModalsAction {
  type: ActionTypes.POP;
}

export type ModalsAction = PushModalsAction | PopModalsAction;