import { Action } from '../types';
import { Project } from '../../models/Project';

export enum ActionTypes {
  PENDING = 'projects/PENDING',
  LOADING = 'projects/LOADING',
  LOADED = 'projects/LOADED'
}

export interface ProjectsState {
  data: Project[];
  error: boolean;
  state: ActionTypes;
}

interface PendingProjectsAction {
  type: ActionTypes.PENDING;
}

interface LoadingProjectsAction {
  type: ActionTypes.LOADING;
}

interface LoadedProjectsAction extends Action {
  type: ActionTypes.LOADED;
  payload: {
    projects: Project[]
  };
}

export type ProjectsAction = PendingProjectsAction
  | LoadingProjectsAction
  | LoadedProjectsAction;