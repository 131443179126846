import { AnyAction, Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Postit } from '../../models/Postit';
import { http } from '../../helpers/utils';
import { ActionTypes } from './types';
import { State } from '../rootReducer';
import { getToken } from '../auth';
import { load as loadBakery } from '../bakery';

export const remove = (projectId: number, bakeryId: number, postitId: number) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const token = getToken(getState());

    dispatch({
      type: ActionTypes.DELETING
    });

    dispatch({
      type: ActionTypes.DELETED,
      payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${projectId}/bakery/${bakeryId}/postit/${postitId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`
          },
        })
        .then(() => {
          dispatch(loadBakery(projectId, bakeryId));
        })
    });
  };

export const load = (projectId: number, bakeryId: number, postitId: number) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const token = getToken(getState());

    dispatch({
      type: ActionTypes.LOADING
    });

    dispatch({
      type: ActionTypes.LOADED,
      payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${projectId}/bakery/${bakeryId}/postit/${postitId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          },
        })
        .then((res: Response) => res.json())
        .then((postit: Postit) => postit)
    });
  };

export const clear = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch({
    type: ActionTypes.CLEAR
  });
};