import { Action } from '../types';
import { Postit } from '../../models/Postit';
import { Tag } from '../../models/Tag';

export enum ActionTypes {
  INITIAL = 'tag/INITIAL',

  CLEAR_CONNECTED = 'tag/CLEAR_CONNECTED',
  LOADING_CONNECTED = 'tag/LOADING_CONNECTED',
  LOADED_CONNECTED = 'tag/LOADED_CONNECTED',

  LOADED = 'tag/LOADED',
  DELETING = 'tag/DELETING',
  DELETED = 'tag/DELETED',
  UPDATING = 'tag/UPDATING',
  UPDATED = 'tag/UPDATED',
}

export interface TagState {
  data: Tag | null;
  connectedPostits: Postit[];
  error: boolean;
  state: ActionTypes;
}

interface InitialTagAction {
  type: ActionTypes.INITIAL;
}

interface ClearConnectedTagAction {
  type: ActionTypes.CLEAR_CONNECTED;
}

interface LoadingConnectedTagAction {
  type: ActionTypes.LOADING_CONNECTED;
}

interface LoadedConnectedTagAction extends Action {
  type: ActionTypes.LOADED_CONNECTED;
  payload: Postit[];
}

interface LoadedTagAction extends Action {
  type: ActionTypes.LOADED;
  payload: Tag;
}

interface DeletingTagAction {
  type: ActionTypes.DELETING;
}

interface DeletedTagAction {
  type: ActionTypes.DELETED;
}

interface UpdatingTagAction {
  type: ActionTypes.UPDATING;
}

interface UpdatedTagAction extends Action {
  type: ActionTypes.UPDATED;
}

export type TagAction = InitialTagAction
  | ClearConnectedTagAction
  | LoadingConnectedTagAction
  | LoadedConnectedTagAction
  | LoadedTagAction
  | DeletingTagAction
  | DeletedTagAction
  | UpdatingTagAction
  | UpdatedTagAction;