import { Dispatch, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Bakery } from '../../models/Bakery';
import { http } from '../../helpers/utils';
import { ActionTypes } from './types';
import { State } from '../rootReducer';
import { getToken } from '../auth';

export const load = (projectId?: number) => (dispatch: Dispatch<AnyAction>, getState: () => State) => {
  const token = getToken(getState());
  const url = projectId !== undefined
    ? `${process.env.RAZZLE_APP_API}/api/v1/project/${projectId}/bakery`
    : `${process.env.RAZZLE_APP_API}/api/v1/bakeries`;

  dispatch({
    type: ActionTypes.LOADING
  });

  dispatch({
    type: ActionTypes.LOADED,
    payload: http(url,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
      })
      .then((res: Response) => res.json())
  });
};

export const create = (projectId: number, name: string) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const token = getToken(getState());

    dispatch({
      type: ActionTypes.CREATING
    });

    dispatch({
      type: ActionTypes.CREATED,
      payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${projectId}/bakery`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            name
          })
        })
        .then((res) => res.json())
        .then(() => dispatch(load()))
    });
  };

export const update = (bakery: Bakery) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const token = getToken(getState());

    dispatch({
      type: ActionTypes.UPDATING
    });

    dispatch({
      type: ActionTypes.UPDATED,
      payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${bakery.project_id}/bakery/${bakery.id}`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            name: bakery.name
          })
        })
        .then((res) => res.json())
        .then(() => dispatch(load()))
    });
  };

export const deleteBakery = (bakery: Bakery) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const token = getToken(getState());

    dispatch({
      type: ActionTypes.DELETING
    });

    dispatch({
      type: ActionTypes.DELETED,
      payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${bakery.project_id}/bakery/${bakery.id}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`
          },
        })
        .then(() => dispatch(load()))
    });
  };