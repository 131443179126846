import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { withRouter } from 'react-router-dom';

import { StateFromProps, DispatchFromProps } from './types';
import { State } from '../../store/rootReducer';
import { getIsPending, verifyAuth } from '../../store/auth';
import App from './component';

const mapStateToProps = (state: State): StateFromProps => ({
  isAuthPending: getIsPending(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State, any, AnyAction>): DispatchFromProps => ({
  verifyAuth: () => dispatch(verifyAuth())
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App));