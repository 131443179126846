import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import * as auth from './auth';
import * as bakeries from './bakeries';
import * as bakery from './bakery';
import * as dimension from './dimension';
import * as dnd from './dnd';
import * as modals from './modals';
import * as postit from './postit';
import * as project from './project';
import * as projects from './projects';
import * as routeHistory from './routeHistory';
import * as tag from './tag';
import * as tags from './tags';

export interface State {
  auth: auth.AuthState;
  bakeries: bakeries.BakeriesState;
  bakery: bakery.BakeryState;
  dimension: dimension.DimensionState;
  dnd: dnd.DndState;
  modals: modals.ModalsState;
  postit: postit.PostitState;
  project: project.ProjectState;
  projects: projects.ProjectsState;
  routeHistory: routeHistory.RouteHistoryState;
  tag: tag.TagState;
  tags: tags.TagsState;
}

export const rootState: State = {
  auth: auth.initialState,
  bakeries: bakeries.initialState,
  bakery: bakery.initialState,
  dimension: dimension.initialState,
  dnd: dnd.initialState,
  modals: modals.initialState,
  postit: postit.initialState,
  project: project.initialState,
  projects: projects.initialState,
  routeHistory: routeHistory.initialState,
  tag: tag.initialState,
  tags: tags.initialState,
};

export const rootReducer = (history: History<any>) => combineReducers<State>({
  auth: auth.reducer,
  bakeries: bakeries.reducer,
  bakery: bakery.reducer,
  dimension: dimension.reducer,
  dnd: dnd.reducer,
  modals: modals.reducer,
  postit: postit.reducer,
  project: project.reducer,
  projects: projects.reducer,
  routeHistory: routeHistory.routeHistoryReducer,
  router: connectRouter(history),
  tag: tag.reducer,
  tags: tags.reducer,
} as any);