import { Action } from '../types';
import { Tag } from '../../models/Tag';

export enum ActionTypes {
  PENDING = 'tags/PENDING',
  LOADING = 'tags/LOADING',
  LOADED = 'tags/LOADED'
}

export interface TagsState {
  data: Tag[];
  error: boolean;
  state: ActionTypes;
}

interface PendingTagsAction {
  type: ActionTypes.PENDING;
}

interface LoadingTagsAction {
  type: ActionTypes.LOADING;
}

interface LoadedTagsAction extends Action {
  type: ActionTypes.LOADED;
  payload: {
    tags: Tag[]
  };
}

export type TagsAction = PendingTagsAction
  | LoadingTagsAction
  | LoadedTagsAction;