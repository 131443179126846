import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { http } from '../../helpers/utils';
import { ActionTypes } from './types';
import { State } from '../rootReducer';
import { getToken } from '../auth';
import { Project } from '../../models/Project';
import { load as loadProjects } from '../projects';

export const create = (name: string, section: string) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const token = getToken(getState());

    dispatch({
      type: ActionTypes.CREATING
    });

    dispatch({
      type: ActionTypes.CREATED,
      payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            name,
            section
          })
        })
        .then((res: Response) => res.json())
        .then((project: Project) => {
          dispatch(loadProjects());
          return project;
        })
    });
  };

export const updateCollaborators = (project: Project, collaborators: string[]) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const token = getToken(getState());

    dispatch({
      type: ActionTypes.COLLABORATORS_UPDATING
    });

    dispatch({
      type: ActionTypes.COLLABORATORS_UPDATED,
      payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${project.id}/collaborators`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            collaborators
          })
        })
        .then((_) => {
          dispatch(loadProjects());
        })
    });
  };

export const update = (project: Project) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const token = getToken(getState());

    dispatch({
      type: ActionTypes.UPDATING
    });

    dispatch({
      type: ActionTypes.UPDATED,
      payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${project.id}`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            name: project.name,
            section: project.section
          })
        })
        .then((res: Response) => res.json())
        .then((res: Project) => {
          dispatch(loadProjects());
          return res;
        })
    });
  };

export const deleteProject = (projectId: number) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const token = getToken(getState());

    dispatch({
      type: ActionTypes.DELETING
    });

    dispatch({
      type: ActionTypes.DELETED,
      payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${projectId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`
          },
        })
        .then(() => dispatch(loadProjects()))
    });
  };