import { Action } from '../types';
import { User } from '../../models/User';

export enum ActionTypes {
  PENDING = 'auth/PENDING',

  CREATE_REQUEST = 'auth/CREATE_REQUEST',
  CREATE_SUCCESS = 'auth/CREATE_SUCCESS',
  CREATE_FAILURE = 'auth/CREATE_FAILURE',

  LOGIN_REQUEST = 'auth/LOGIN_REQUEST',
  LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS',
  LOGIN_FAILURE = 'auth/LOGIN_FAILURE',

  LOGOUT = 'auth/LOGOUT',

  VERIFY_REQUEST = 'auth/VERIFY_REQUEST',
  VERIFY_SUCCESS = 'auth/VERIFY_SUCCESS',
  VERIFY_FAILURE = 'auth/VERIFY_FAILURE'
}

export interface OAuthResponse {
  access_token: string;
  token_type: string;
}

export interface AuthState {
  state: ActionTypes;
  isLoggingIn: boolean;
  isVerifying: boolean;
  verifyingError: boolean;
  loginError: boolean;
  isAuthenticated: boolean;
  token: string;
  user: User | null;
}

interface CreateRequestAction {
  type: ActionTypes.CREATE_REQUEST;
}

interface CreateSuccessAction {
  type: ActionTypes.CREATE_SUCCESS;
}

interface CreateFailureAction {
  type: ActionTypes.CREATE_FAILURE;
}

interface LoginRequestAction {
  type: ActionTypes.LOGIN_REQUEST;
}

interface LoginSuccessAction {
  type: ActionTypes.LOGIN_SUCCESS;
}

interface LoginFailureAction {
  type: ActionTypes.LOGIN_FAILURE;
}

interface LogoutAction {
  type: ActionTypes.LOGOUT;
}

interface VerifyRequestAction {
  type: ActionTypes.VERIFY_REQUEST;
}

interface VerifySuccessAction extends Action {
  type: ActionTypes.VERIFY_SUCCESS;
  payload: {
    token: string;
    user: User;
  };
}

interface VerifyFailureAction {
  type: ActionTypes.VERIFY_FAILURE;
}

export type AuthAction = CreateRequestAction
  | CreateSuccessAction
  | CreateFailureAction
  | LoginRequestAction
  | LoginSuccessAction
  | LoginFailureAction
  | LogoutAction
  | VerifyRequestAction
  | VerifySuccessAction
  | VerifyFailureAction;