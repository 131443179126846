import { Dispatch, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { ActionTypes, OAuthResponse } from './types';
import { State } from '../rootReducer';
import { http } from '../../helpers/utils';

export const create = (name: string, email: string, password: string) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>) => {
    dispatch({
      type: ActionTypes.CREATE_REQUEST
    });

    http(`${process.env.RAZZLE_APP_API}/api/v1/user`,
      {
        method: 'POST',
        redirect: 'follow',
        body: JSON.stringify({
          name,
          email: email.toLowerCase(),
          password
        })
      })
      .then((_) => {
        dispatch({
          type: ActionTypes.CREATE_SUCCESS,
        });

        dispatch(login(email, password));
      })
      .catch((_e) => {
        dispatch({
          type: ActionTypes.CREATE_FAILURE,
        });
      });
  };

export const login = (email: string, password: string) => (dispatch: ThunkDispatch<State, {}, AnyAction>) => {
  dispatch({
    type: ActionTypes.LOGIN_REQUEST
  });

  http(`${process.env.RAZZLE_APP_API}/api/v1/login/token`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: `username=${email.toLowerCase()}&password=${password}`
    })
    .then((res: Response) => res.json())
    .then((res: OAuthResponse) => {
      window.localStorage.setItem('token', res.access_token);

      dispatch({
        type: ActionTypes.LOGIN_SUCCESS,
      });

      dispatch(verifyAuth());
    })
    .catch((_e) => {
      dispatch({
        type: ActionTypes.LOGIN_FAILURE,
      });
    });
};

export const logout = () => (dispatch: Dispatch<AnyAction>) => {
  window.localStorage.setItem('token', '');

  dispatch({
    type: ActionTypes.LOGOUT
  });
};

export const verifyAuth = () => (dispatch: Dispatch<AnyAction>) => {
  dispatch({
    type: ActionTypes.VERIFY_REQUEST
  });

  const token = window.localStorage.getItem('token');
  if (!token) {
    dispatch({
      type: ActionTypes.VERIFY_FAILURE,
    });
    return;
  }

  dispatch({
    type: ActionTypes.VERIFY_SUCCESS,
    payload: http(`${process.env.RAZZLE_APP_API}/api/v1/user/me`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res: Response) => res.json())
      .then((res: any) => ({
        token,
        user: res
      }))
      .catch((_e) => {
        dispatch({
          type: ActionTypes.VERIFY_FAILURE,
        });
      })
  });
};