import { Dispatch } from 'redux';

import {
  ActionTypes,
  DndAction
} from './types';

export const setData = (data: any) => (dispatch: Dispatch<DndAction>) => {
  dispatch({
    type: ActionTypes.SET_DATA,
    data
  });
};