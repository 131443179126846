import {
  ModalsState,
  ModalsAction,
  ActionTypes
} from './types';

export const initialState: ModalsState = {
  queue: []
};

export const reducer = (
  state: ModalsState = initialState,
  action: ModalsAction
): ModalsState => {
  switch (action.type) {
    case ActionTypes.PUSH:
      return {
        queue: [...state.queue, action.modal],
      };
    case ActionTypes.POP:
      const queue = [...state.queue];
      queue.pop();

      return {
        queue
      };
    default:
      return state;
  }
};
