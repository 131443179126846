import { createSelector } from 'reselect';

import { State } from '../rootReducer';
import { ProjectsState, ActionTypes } from './types';

export const getProjectsState = ((state: State) => state.projects);

export const isPending = createSelector([getProjectsState], (s: ProjectsState) =>
  s.state === ActionTypes.PENDING);

export const isLoading = createSelector([getProjectsState], (s: ProjectsState) =>
  s.state === ActionTypes.LOADING);

export const getProjects = createSelector([getProjectsState], (s: ProjectsState) => s.data);