import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { Bakery } from '../../models/Bakery';
import { Postit } from '../../models/Postit';
import { http } from '../../helpers/utils';
import { ActionTypes } from './types';
import { State } from '../rootReducer';
import { getBakery } from './selector';
import { getToken } from '../auth';

export const clear = () => (dispatch: ThunkDispatch<State, {}, AnyAction>) => {
  dispatch({
    type: ActionTypes.CLEAR
  });
};

export const download = (bakery: Bakery) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const token = getToken(getState());

    dispatch({
      type: ActionTypes.DOWNLOAD,
      payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${bakery.project_id}/bakery/${bakery.id}/download`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          },
        })
        .then((res: Response) => res.blob())
        .then((blob: Blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const dataUrl = reader.result as string;
            const base64 = 'data:text/csv;base64' + dataUrl;
            const a = document.createElement('a');
            a.href = encodeURI(base64);
            a.download = 'bakery_export.csv';
            a.click();
          };

          reader.readAsDataURL(blob);
        })
    });
  };

export const load = (projectId: number, bakeryId: number) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const token = getToken(getState());

    dispatch({
      type: ActionTypes.LOADING
    });

    dispatch({
      type: ActionTypes.LOADED,
      payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${projectId}/bakery/${bakeryId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          },
        })
        .then((res: Response) => res.json())
        .then((bakery: Bakery) => {
          if (bakery.postits) {
            bakery.postits.map((postit) => {
              postit.layer_id -= 1;
            });
          }

          return bakery;
        })
    });
  };

export const addPostit = (projectId: number, postit: Partial<Postit>) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const state = getState();
    const token = getToken(state);
    const bakery = getBakery(state);

    if (bakery) {
      dispatch({
        type: ActionTypes.ADDING_POSTIT
      });

      dispatch({
        type: ActionTypes.ADDED_POSTIT,
        payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${projectId}/bakery/${bakery.id}/postit`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
              ...postit,
              layer_id: postit.layer_id
                ? postit.layer_id + 1
                : 1,
            } as unknown as Postit)
          })
          .then(() => dispatch(load(projectId, bakery.id)))
      });
    }
  };

export const updatePostit = (postit: Postit) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const state = getState();
    const token = getToken(state);
    const bakery = getBakery(state);

    if (bakery) {
      dispatch({
        type: ActionTypes.UPDATING_POSTIT
      });

      dispatch({
        type: ActionTypes.UPDATED_POSTIT,
        payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${bakery.project_id}/bakery/${bakery.id}/postit/${postit.id}`,
          {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify({
              ...postit,
              layer_id: postit.layer_id
                ? postit.layer_id + 1
                : 1,
            } as unknown as Postit)
          })
          .then((_res) => dispatch(load(bakery.project_id, bakery.id)))
      });
    }
  };