export enum ActionTypes {
  SET_DATA = 'dnd/SET_DATA'
}

export interface DndState {
  data: any;
}

interface SetDateDndAction {
  type: ActionTypes.SET_DATA;
  data: any;
}

export type DndAction = SetDateDndAction;