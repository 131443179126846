import { connect } from 'react-redux';

import { StateFromProps } from './types';
import { State } from '../../store/rootReducer';
import { getIsAuthenticated, getIsVerifying } from '../../store/auth';
import PrivateRoute from './component';

const mapStateToProps = (state: State): StateFromProps => ({
  isLoggedIn: getIsAuthenticated(state),
  isVerifying: getIsVerifying(state)
});

export default connect(
  mapStateToProps,
  null
)(PrivateRoute) as any;