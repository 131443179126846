import { createSelector } from 'reselect';

import { State } from '../rootReducer';
import { ProjectState, ActionTypes } from './types';

export const getProjectState = ((state: State) => state.project);

export const isCreated = createSelector([getProjectState], (s: ProjectState) =>
  s.state === ActionTypes.CREATED);

export const isDeleted = createSelector([getProjectState], (s: ProjectState) =>
  s.state === ActionTypes.DELETED);

export const isUpdated = createSelector([getProjectState], (s: ProjectState) =>
  s.state === ActionTypes.UPDATED || s.state === ActionTypes.COLLABORATORS_UPDATED);