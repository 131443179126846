import {
  DndState,
  DndAction,
  ActionTypes
} from './types';

export const initialState: DndState = {
  data: null,
};

export const reducer = (
  state: DndState = initialState,
  action: DndAction
): DndState => {
  switch (action.type) {
    case ActionTypes.SET_DATA:
      return {
        data: action.data,
      };
    default:
      return state;
  }
};