import { Action } from '../types';
import { Bakery } from '../../models/Bakery';

export enum ActionTypes {
  PENDING = 'bakery/PENDING',
  CLEAR = 'bakery/PENDING',
  DOWNLOAD = 'bakery/DOWNLOAD',

  LOADING = 'bakery/LOADING',
  LOADED = 'bakery/LOADED',

  ADDING_POSTIT = 'bakery/ADDING_POSTIT',
  ADDED_POSTIT = 'bakery/ADDED_POSTIT',

  UPDATING_POSTIT = 'bakery/UPDATING_POSTIT',
  UPDATED_POSTIT = 'bakery/UPDATED_POSTIT',
}

export interface BakeryState {
  data: Bakery | null;
  error: boolean;
  state: ActionTypes;
}

interface PendingBakeryAction {
  type: ActionTypes.PENDING;
}

interface ClearBakeryAction {
  type: ActionTypes.CLEAR;
}

interface DownloadBakeryAction extends Action {
  type: ActionTypes.DOWNLOAD;
}

interface LoadingBakeryAction {
  type: ActionTypes.LOADING;
}

interface LoadedBakeryAction extends Action {
  type: ActionTypes.LOADED;
  payload: Bakery;
}

interface AddingPostitBakeryAction {
  type: ActionTypes.ADDING_POSTIT;
}

interface AddedPostitBakeryAction {
  type: ActionTypes.ADDED_POSTIT;
}

interface UpdatingBakeryAction {
  type: ActionTypes.UPDATING_POSTIT;
}

interface UpdatedBakeryAction {
  type: ActionTypes.UPDATED_POSTIT;
}

export type BakeryAction = PendingBakeryAction
  | ClearBakeryAction
  | DownloadBakeryAction
  | LoadingBakeryAction
  | LoadedBakeryAction
  | AddingPostitBakeryAction
  | AddedPostitBakeryAction
  | UpdatingBakeryAction
  | UpdatedBakeryAction;