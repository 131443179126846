import { Action } from '../types';
import { Bakery } from '../../models/Bakery';

export enum ActionTypes {
  PENDING = 'bakeries/PENDING',

  CREATING = 'bakeries/CREATING',
  CREATED = 'bakeries/CREATED',

  DELETING = 'bakeries/DELETING',
  DELETED = 'bakeries/DELETED',

  UPDATING = 'bakeries/UPDATING',
  UPDATED = 'bakeries/UPDATED',

  LOADING = 'bakeries/LOADING',
  LOADED = 'bakeries/LOADED'
}

export interface BakeriesState {
  data: Bakery[];
  error: boolean;
  state: ActionTypes;
}

interface PendingBakeriesAction {
  type: ActionTypes.PENDING;
}

interface CreatingBakeryAction {
  type: ActionTypes.CREATING;
}

interface CreatedBakeryAction extends Action {
  type: ActionTypes.CREATED;
  payload: Bakery;
}

interface DeletingBakeryAction {
  type: ActionTypes.DELETING;
}

interface DeletedBakeryAction extends Action {
  type: ActionTypes.DELETED;
  payload: any;
}

interface UpdatingBakeryAction {
  type: ActionTypes.UPDATING;
}

interface UpdatedBakeryAction extends Action {
  type: ActionTypes.UPDATED;
  payload: Bakery;
}

interface LoadingBakeriesAction {
  type: ActionTypes.LOADING;
}

interface LoadedBakeriesAction extends Action {
  type: ActionTypes.LOADED;
  payload: {
    bakeries: Bakery[]
  };
}

export type BakeriesAction = PendingBakeriesAction
  | CreatingBakeryAction
  | CreatedBakeryAction
  | DeletingBakeryAction
  | DeletedBakeryAction
  | UpdatingBakeryAction
  | UpdatedBakeryAction
  | LoadingBakeriesAction
  | LoadedBakeriesAction;