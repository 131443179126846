import { createSelector } from 'reselect';

import { State } from '../rootReducer';
import { AuthState, ActionTypes } from './types';

export const getAuthState = ((state: State) => state.auth);

export const getIsPending = createSelector([getAuthState], (s: AuthState) => s.state === ActionTypes.PENDING);
export const getIsLoggingIn = createSelector([getAuthState], (s: AuthState) => s.isLoggingIn);
export const getIsAuthenticated = createSelector([getAuthState], (s: AuthState) => s.isAuthenticated);
export const getIsVerifying = createSelector([getAuthState], (s: AuthState) => s.isVerifying);
export const getLoginError = createSelector([getAuthState], (s: AuthState) => s.loginError);
export const getToken = createSelector([getAuthState], (s: AuthState) => s.token);
export const getUser = createSelector([getAuthState], (s: AuthState) => s.user);