// import 'react-app-polyfill/ie11';
// import 'ts-polyfill';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { HelmetProvider } from 'react-helmet-async';
import { loadableReady } from '@loadable/component';

import App from './app/components/App';
import configureStore from './app/store';

const history = createBrowserHistory();
const store = configureStore((window as any).__PRELOADED_STATE__, history);

if (process.env.NODE_ENV === 'production') {
  /* tslint:disable:no-empty */
  window.console.log = () => { };
  window.console.info = () => { };
  window.console.error = () => { };
}

loadableReady(() =>
  hydrate(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('app-root')
  )
);

if (module.hot) {
  module.hot.accept('./app/components/App', () => {
    loadableReady(() =>
      hydrate(
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </ConnectedRouter>
        </Provider>,
        document.getElementById('app-root')
      )
    );
  });
}
