import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Props } from './types';

const PrivateRoute: React.FC<Props> = (props) => {
  const { component, isLoggedIn, isVerifying, ...rest } = props;

  const routeComponent = (componentProps: any) => {
    if (isVerifying) {
      return null;
    }

    return isLoggedIn
      ? React.createElement(component, componentProps)
      : <Redirect to={{ pathname: '/login' }} />;
  };

  return <Route {...rest} render={routeComponent} />;
};

export default PrivateRoute;