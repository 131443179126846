import { Action } from '../types';
import { Postit } from '../../models/Postit';

export enum ActionTypes {
  PENDING = 'postit/PENDING',

  CLEAR = 'postit/CLEAR',

  DELETING = 'postit/DELETING',
  DELETED = 'postit/DELETED',

  LOADING = 'postit/LOADING',
  LOADED = 'postit/LOADED',
}

export interface PostitState {
  data: Postit | null;
  error: boolean;
  state: ActionTypes;
}

interface PendingPostitAction {
  type: ActionTypes.PENDING;
}

interface ClearPostitAction {
  type: ActionTypes.CLEAR;
}

interface DeletingPostitAction {
  type: ActionTypes.DELETING;
}

interface DeletedPostitAction {
  type: ActionTypes.DELETED;
}

interface LoadingPostitAction {
  type: ActionTypes.LOADING;
}

interface LoadedPostitAction extends Action {
  type: ActionTypes.LOADED;
  payload: Postit;
}

export type PostitAction = PendingPostitAction
  | ClearPostitAction
  | DeletingPostitAction
  | DeletedPostitAction
  | LoadingPostitAction
  | LoadedPostitAction;