import { createSelector } from 'reselect';

import { State } from '../rootReducer';
import { TagState, ActionTypes } from './types';

export const getTagState = ((state: State) => state.tag);

export const isDeleted = createSelector([getTagState], (s: TagState) =>
  s.state === ActionTypes.DELETED);

export const isUpdated = createSelector([getTagState], (s: TagState) =>
  s.state === ActionTypes.UPDATED);

export const getConnectedPostits = createSelector([getTagState], (s: TagState) => s.connectedPostits);