import {
  TagState,
  TagAction,
  ActionTypes
} from './types';

export const initialState: TagState = {
  data: null,
  connectedPostits: [],
  error: false,
  state: ActionTypes.INITIAL
};

export const reducer = (
  state: TagState = initialState,
  action: TagAction
): TagState => {
  switch (action.type) {
    case ActionTypes.INITIAL:
    case ActionTypes.DELETING:
    case ActionTypes.UPDATING:
      return {
        ...state,
        error: false,
        state: action.type,
      };
    case ActionTypes.CLEAR_CONNECTED:
    case ActionTypes.LOADING_CONNECTED:
      return {
        ...state,
        connectedPostits: [],
        error: false,
        state: action.type,
      };
    case ActionTypes.LOADED_CONNECTED:
      return {
        ...state,
        connectedPostits: action.error
          ? state.connectedPostits
          : action.payload,
        error: false, // don't care about connected tags error
        state: action.type,
      };
    case ActionTypes.LOADED:
    case ActionTypes.UPDATED:
      return {
        ...state,
        data: action.error
          ? state.data
          : action.payload,
        error: action.error,
        state: action.type,
      };
    case ActionTypes.DELETED:
      return {
        ...state,
        data: null,
        error: false,
        state: action.type,
      };
    default:
      return state;
  }
};
