import { Dispatch, AnyAction } from 'redux';

import { http } from '../../helpers/utils';
import { ActionTypes } from './types';
import { State } from '../rootReducer';
import { getToken } from '../auth';
import { Bakery } from '../../models/Bakery';

export const load = (bakery: Bakery) => (dispatch: Dispatch<AnyAction>, getState: () => State) => {
  const token = getToken(getState());

  dispatch({
    type: ActionTypes.LOADING
  });

  dispatch({
    type: ActionTypes.LOADED,
    payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${bakery.project_id}/bakery/${bakery.id}/tag`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        },
      })
      .then((res: Response) => res.json())
  });
};
