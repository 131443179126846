import {
  BakeriesState,
  BakeriesAction,
  ActionTypes
} from './types';

export const initialState: BakeriesState = {
  data: [],
  error: false,
  state: ActionTypes.PENDING
};

export const reducer = (
  state: BakeriesState = initialState,
  action: BakeriesAction
): BakeriesState => {
  switch (action.type) {
    case ActionTypes.PENDING:
    case ActionTypes.LOADING:
    case ActionTypes.CREATING:
    case ActionTypes.CREATED:
    case ActionTypes.DELETING:
    case ActionTypes.DELETED:
    case ActionTypes.UPDATING:
    case ActionTypes.UPDATED:
      return {
        ...state,
        state: action.type,
      };
    case ActionTypes.LOADED:
      return {
        data: action.error
          ? state.data
          : action.payload.bakeries,
        error: action.error,
        state: action.type,
      };
    default:
      return state;
  }
};
