import {
  BakeryState,
  BakeryAction,
  ActionTypes
} from './types';

export const initialState: BakeryState = {
  data: null,
  error: false,
  state: ActionTypes.PENDING
};

export const reducer = (
  state: BakeryState = initialState,
  action: BakeryAction
): BakeryState => {
  switch (action.type) {
    case ActionTypes.PENDING:
    case ActionTypes.LOADING:
      return {
        ...state,
        state: action.type,
      };
    case ActionTypes.CLEAR:
      return {
        ...state,
        data: null,
        state: action.type,
      };
    case ActionTypes.DOWNLOAD:
      return {
        ...state,
        error: action.error,
        state: action.type,
      };
    case ActionTypes.ADDING_POSTIT:
    case ActionTypes.ADDED_POSTIT:
    case ActionTypes.UPDATING_POSTIT:
    case ActionTypes.UPDATED_POSTIT:
      return {
        ...state,
        state: action.type,
      };
    case ActionTypes.LOADED:
      return {
        ...state,
        data: action.error
          ? state.data
          : action.payload,
        error: action.error,
        state: action.type,
      };
    default:
      return state;
  }
};
