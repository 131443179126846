import { createSelector } from 'reselect';

import { State } from '../rootReducer';
import { TagsState, ActionTypes } from './types';

export const getTagsState = ((state: State) => state.tags);

export const isPending = createSelector([getTagsState], (s: TagsState) =>
  s.state === ActionTypes.PENDING);

export const isLoading = createSelector([getTagsState], (s: TagsState) =>
  s.state === ActionTypes.LOADING);

export const getTags = createSelector([getTagsState], (s: TagsState) => s.data);