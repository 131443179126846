import {
  TagsState,
  TagsAction,
  ActionTypes
} from './types';

export const initialState: TagsState = {
  data: [],
  error: false,
  state: ActionTypes.PENDING
};

export const reducer = (
  state: TagsState = initialState,
  action: TagsAction
): TagsState => {
  switch (action.type) {
    case ActionTypes.PENDING:
    case ActionTypes.LOADING:
      return {
        ...state,
        state: action.type,
      };
    case ActionTypes.LOADED:
      return {
        data: action.error
          ? state.data
          : action.payload.tags,
        error: action.error,
        state: action.type,
      };
    default:
      return state;
  }
};
