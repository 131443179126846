import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { http } from '../../helpers/utils';
import { ActionTypes } from './types';
import { State } from '../rootReducer';
import { getToken } from '../auth';
import { Bakery } from '../../models/Bakery';
import { Postit } from '../../models/Postit';
import { Tag } from '../../models/Tag';
import { load as loadBakery } from '../bakery';
import { load as loadTags } from '../tags';

export const clearConnectedPostits = () => (dispatch: ThunkDispatch<State, {}, AnyAction>) => {
  dispatch({
    type: ActionTypes.CLEAR_CONNECTED
  });
};

export const loadConnectedPostits = (bakery: Bakery, tag: Tag) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const token = getToken(getState());

    dispatch({
      type: ActionTypes.LOADING_CONNECTED
    });

    dispatch({
      type: ActionTypes.LOADED_CONNECTED,
      payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${bakery.project_id}/bakery/${bakery.id}/tag/${tag.id}/postits`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res: Response) => res.json())
        .then((res: { postits: Postit[] }) => res.postits)
    });
  };

export const update = (bakery: Bakery, tag: Tag) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const token = getToken(getState());

    dispatch({
      type: ActionTypes.UPDATING
    });

    dispatch({
      type: ActionTypes.UPDATED,
      payload: http(`${process.env.RAZZLE_APP_API}/api/v1/project/${bakery.project_id}/bakery/${bakery.id}/tag/${tag.id}`,
        {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(tag)
        })
        .then((res: Response) => res.json())
        .then((res: Tag) => {
          dispatch(loadTags(bakery));
          return res;
        })
    });
  };

export const deleteTag = (bakery: Bakery, tag: Tag) =>
  (dispatch: ThunkDispatch<State, {}, AnyAction>, getState: () => State) => {
    const token = getToken(getState());

    dispatch({
      type: ActionTypes.DELETING
    });

    http(`${process.env.RAZZLE_APP_API}/api/v1/project/${bakery.project_id}/bakery/${bakery.id}/tag/${tag.id}`,
      {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`
        },
      })
      .then((_) => {
        dispatch({
          type: ActionTypes.DELETED
        });

        dispatch(loadTags(bakery));
        dispatch(loadBakery(bakery.project_id, bakery.id));
      });
  };