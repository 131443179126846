import {
  AuthState,
  AuthAction,
  ActionTypes
} from './types';

export const initialState: AuthState = {
  state: ActionTypes.PENDING,
  isLoggingIn: false,
  isVerifying: false,
  verifyingError: false,
  loginError: false,
  isAuthenticated: false,
  token: '',
  user: null
};

export const reducer = (
  state: AuthState = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
    case ActionTypes.CREATE_REQUEST:
      return {
        ...state,
        state: action.type,
        isLoggingIn: true,
        loginError: false
      };
    case ActionTypes.LOGIN_SUCCESS:
    case ActionTypes.CREATE_SUCCESS:
      return {
        ...state,
        state: action.type,
        isLoggingIn: false,
        loginError: false,
      };
    case ActionTypes.LOGIN_FAILURE:
    case ActionTypes.CREATE_FAILURE:
      return {
        ...state,
        state: action.type,
        isLoggingIn: false,
        isAuthenticated: false,
        loginError: true
      };
    case ActionTypes.LOGOUT:
      return {
        ...initialState,
        state: action.type,
      };
    case ActionTypes.VERIFY_REQUEST:
      return {
        ...state,
        state: action.type,
        isVerifying: true,
        verifyingError: false
      };
    case ActionTypes.VERIFY_SUCCESS:
      return {
        ...state,
        state: action.type,
        isAuthenticated: !action.error,
        isVerifying: false,
        verifyingError: !!action.error,
        token: action.error
          ? state.token
          : action.payload.token,
        user: action.error
          ? state.user
          : action.payload.user
      };
    case ActionTypes.VERIFY_FAILURE:
      return {
        ...state,
        state: action.type,
        isVerifying: false,
        verifyingError: true
      };
    default:
      return state;
  }
};
