import {
  PostitState,
  PostitAction,
  ActionTypes
} from './types';

export const initialState: PostitState = {
  data: null,
  error: false,
  state: ActionTypes.PENDING
};

export const reducer = (
  state: PostitState = initialState,
  action: PostitAction
): PostitState => {
  switch (action.type) {
    case ActionTypes.PENDING:
    case ActionTypes.DELETING:
    case ActionTypes.LOADING:
      return {
        ...state,
        state: action.type,
      };
    case ActionTypes.LOADED:
      return {
        data: action.error
          ? state.data
          : action.payload,
        error: action.error,
        state: action.type,
      };
    case ActionTypes.CLEAR:
      return {
        ...state,
        data: null,
        error: false,
        state: action.type
      };
    case ActionTypes.DELETED:
      return {
        ...initialState,
        state: action.type
      };
    default:
      return state;
  }
};
