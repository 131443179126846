import {
  ProjectState,
  ProjectAction,
  ActionTypes
} from './types';

export const initialState: ProjectState = {
  data: null,
  error: false,
  state: ActionTypes.INITIAL
};

export const reducer = (
  state: ProjectState = initialState,
  action: ProjectAction
): ProjectState => {
  switch (action.type) {
    case ActionTypes.INITIAL:
    case ActionTypes.CREATING:
    case ActionTypes.DELETING:
    case ActionTypes.UPDATING:
    case ActionTypes.COLLABORATORS_UPDATING:
    case ActionTypes.COLLABORATORS_UPDATED:
      return {
        ...state,
        error: false,
        state: action.type,
      };
    case ActionTypes.CREATED:
    case ActionTypes.UPDATED:
      return {
        data: action.error
          ? state.data
          : action.payload,
        error: action.error,
        state: action.type,
      };
    case ActionTypes.DELETED:
      return {
        data: action.error
          ? state.data
          : null,
        error: action.error,
        state: action.type,
      };
    default:
      return state;
  }
};
