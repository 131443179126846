import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import loadable from '@loadable/component';

import '../../../assets/styles/styles.scss';
import { isBrowser } from '../../helpers/utils';
import { Props } from './types';
import styles from './styles.module.scss';
import PrivateRoute from '../PrivateRoute';
import ScreenDebugger from '../ScreenDebugger';

const fallback = <div>loading....</div>;

const BakeryPage = loadable(() => import('../../pages/BakeryPage'), { fallback });
const FrontPage = loadable(() => import('../../pages/FrontPage'), { fallback });
const LoginPage = loadable(() => import('../../pages/LoginPage'), { fallback });
const RegisterPage = loadable(() => import('../../pages/RegisterPage'), { fallback });
const ForgotPage = loadable(() => import('../../pages/ForgotPage'), { fallback });
const ResetPage = loadable(() => import('../../pages/ResetPage'), { fallback });
const NotFoundPage = loadable(() => import('../../pages/NotFoundPage'), { fallback });

export default class App extends React.Component<Props> {

  constructor(props: Props) {
    super(props);

    if (this.props.isAuthPending && isBrowser) {
      this.props.verifyAuth();
    }
  }

  public render() {
    return (
      <>
        <Helmet
          defaultTitle="Data Bakery"
          htmlAttributes={{
            dir: 'ltr',
            lang: 'en',
          }}
          titleTemplate="%s | Data Bakery"
        >
          <meta charSet="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta name="description" content="" />
          <link rel="shortcut icon" href="/favicon.ico" />
          <link rel="icon" href="/favicon-16x16.png" sizes="16x16" />
          <link rel="icon" href="/favicon-32x32.png" sizes="32x32" />
          <link rel="icon" href="/favicon-64x64.png" sizes="64x64" />
          <link rel="icon" href="/favicon-96x96.png" sizes="96x96" />
          <link rel="icon" href="/favicon-128x128.png" sizes="128x128" />
          <link rel="icon" href="/favicon-152x152.png" sizes="152x152" />
          <link rel="icon" href="/favicon-167x167.png" sizes="167x167" />
          <link rel="icon" href="/favicon-180x180.png" sizes="180x180" />
          <link rel="icon" href="/favicon-192x192.png" sizes="192x192" />
          <link rel="icon" href="/favicon-196x196.png" sizes="196x196" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png" />
          <meta name="msapplication-TileColor" content="#00C995" />
          <meta name="theme-color" content="#00C995" />
          <meta name="application-name" content="Data Bakery" />
          <meta name="apple-mobile-web-app-title" content="Data Bakery" />
          <meta property="og:site_name" content="Data Bakery" />
          <meta property="og:title" content="Data Bakery" />
          <meta property="og:locale" content="sv-SE" />
        </Helmet>

        <div className={styles.container}>
          <div
            className={styles.main}
            suppressHydrationWarning={true}
          >
            <Switch>
              <PrivateRoute exact={true} path="/" component={FrontPage} />
              <PrivateRoute exact={false} path="/projects/:projectId/bakery/:bakeryId" component={BakeryPage} />
              <Route exact={true} path="/login" component={LoginPage} />
              <Route exact={true} path="/register" component={RegisterPage} />
              <Route exact={true} path="/forgot-password" component={ForgotPage} />
              <Route exact={true} path="/reset-password/:resetId" component={ResetPage} />
              <Route path="/*" component={NotFoundPage} />
            </Switch>
          </div>
        </div>

        <ScreenDebugger />
      </>
    );
  }
}