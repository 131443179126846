import { createSelector } from 'reselect';

import { State } from '../rootReducer';
import { BakeryState, ActionTypes } from './types';

export const getBakeryState = ((state: State) => state.bakery);

export const isPending = createSelector([getBakeryState], (s: BakeryState) =>
  s.state === ActionTypes.PENDING);

export const isLoading = createSelector([getBakeryState], (s: BakeryState) =>
  s.state === ActionTypes.LOADING || s.state === ActionTypes.UPDATING_POSTIT);

export const isSaving = createSelector([getBakeryState], (s: BakeryState) =>
  s.state === ActionTypes.ADDING_POSTIT || s.state === ActionTypes.UPDATING_POSTIT);

export const isDownloadError = createSelector([getBakeryState], (s: BakeryState) =>
  s.state === ActionTypes.DOWNLOAD && s.error);

export const getBakery = createSelector([getBakeryState], (s: BakeryState) => s.data);