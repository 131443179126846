import { createSelector } from 'reselect';

import { State } from '../rootReducer';
import { PostitState, ActionTypes } from './types';

export const getPostitState = ((state: State) => state.postit);

export const isPending = createSelector([getPostitState], (s: PostitState) =>
  s.state === ActionTypes.PENDING);

export const isDeleting = createSelector([getPostitState], (s: PostitState) =>
  s.state === ActionTypes.DELETING);

export const isLoading = createSelector([getPostitState], (s: PostitState) =>
  s.state === ActionTypes.LOADING);

export const getPostit = createSelector([getPostitState], (s: PostitState) => s.data);